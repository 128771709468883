<template>
  <ion-page>
    <!-- Header -->
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <!-- Content -->
    <ion-content fullscreen>
      <!-- Main List -->
      <ion-list>
        <!-- Header -->
        <ion-list-header style="margin-bottom: 2px;">
          Settings
        </ion-list-header>
        <!-- Profile -->
        <ion-item class="profile" lines="none">
          <ion-avatar slot="start" v-if="userAvatar">
            <img :src="userAvatar" alt="Avatar" />
          </ion-avatar>
          <ion-label>
            <h3>{{ userDisplayName }}</h3>
            <h1 v-if="currentSaveName">{{ currentSaveName }}</h1>
            <p v-else>ยังไม่ได้เลือกแฟ้ม</p>
          </ion-label>
        </ion-item>
        <ion-item lines="none" detail="false">
          <ion-icon size="small" slot="start" :ios="icons.folderOutline" :md="icons.folderSharp"></ion-icon>
          <ion-label>ใช้งานแฟ้ม</ion-label>
          <ion-select :interface="saveIonSelectInterface" v-model="save_id" @ionChange="changeSaveId">
            <ion-select-option v-for="save in optionSaves" :key="save.save_id" :value="save.save_id">
              {{ save.save_name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item-divider></ion-item-divider>

        <!-- Assets, Categories, & Custom Report -->
        <template v-if="currentSaveName">
          <!-- Basic -->
          <ion-item-group>
            <ion-item class="light" lines="none" v-if="assetsCount === 0 || categoriesCount === 0">
              <ion-text color="danger" class="small">
                <ion-icon color="danger" :ios="icons.warningOutline" :md="icons.warningSharp"></ion-icon>
                คุณจำเป็นต้องสร้าง บัญชี และ หมวดหมู่ ก่อน จึงจะสามารถใช้งานโปรแกรมได้
              </ion-text>
            </ion-item>
            <ion-item lines="full" button :router-link="{ name: 'assets' }">
              <ion-icon size="small" slot="start" :ios="icons.accountOutline" :md="icons.accountSharp"></ion-icon>
              <ion-label>บัญชี</ion-label>
              <ion-note v-if="assetsCount === 0" color="danger">* คลิกเพื่อเพิ่มบัญชี</ion-note>
            </ion-item>
            <ion-item lines="full" button :router-link="{ name: 'categories' }">
              <ion-icon size="small" slot="start" :ios="icons.categoryOutline" :md="icons.categorySharp"></ion-icon>
              <ion-label>หมวดหมู่</ion-label>
              <ion-note v-if="categoriesCount === 0" color="danger">* คลิกเพื่อเพิ่มหมวดหมู่</ion-note>
            </ion-item>
            <!-- Favorite Assets & Categories -->
            <ion-item v-if="assetsCount > 5 || categoriesCount > 5" lines="full" button :router-link="{ name: 'settings-favoriteAccounts' }">
              <ion-icon size="small" slot="start" :ios="icons.heartOutline" :md="icons.heartSharp"></ion-icon>
              <ion-label>ตั้ง บัญชี/หมวดหมู่ ที่ใช้บ่อย</ion-label>
            </ion-item>
          </ion-item-group>
          <ion-item-divider></ion-item-divider>

          <!-- Custom Report -->
          <ion-item-group>
            <ion-item lines="full" button :router-link="{ name: 'settings-CustomReports' }">
              <ion-icon
                size="small"
                slot="start"
                :ios="icons.reportOutline"
                :md="icons.reportSharp"
              ></ion-icon>
              รายงานแบบกำหนดเอง
            </ion-item>
          </ion-item-group>
          <ion-item-divider></ion-item-divider>
        </template>

        <!-- Logout -->
        <ion-item-divider></ion-item-divider>
        <ion-item-group>
          <ion-item lines="full" button detail="false" @click="onClickLogoutButton">
            <ion-icon color="danger" size="small" slot="start" :ios="icons.iconLogoutOutline" :md="icons.iconLogoutSharp"></ion-icon>
            <ion-label color="danger">ออกจากระบบ</ion-label>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  alertController,
  IonAvatar,
  IonButtons,
  IonContent,
  IonHeader, IonIcon, IonItem, IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton, IonNote,
  IonPage, IonSelect, IonSelectOption, IonText,
  IonToolbar
} from "@ionic/vue";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  diceOutline,
  diceSharp, fileTrayStackedOutline, fileTrayStackedSharp,
  gridOutline,
  gridSharp, heartOutline, heartSharp, pieChartOutline, pieChartSharp,
  powerOutline,
  powerSharp,
  warningOutline, warningSharp
} from "ionicons/icons";
import MixinsIonicAlert from "../../components/MixinsIonicAlert.vue";
import { extractAxiosErrorResponse } from "../../../helper.js";

export default {
  name: "Settings",
  mixins: [MixinsIonicAlert],
  components: {
    IonPage,
    IonContent,
    IonList,
    IonListHeader,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonAvatar,
    IonLabel,
    IonItemGroup,
    IonItemDivider,
    IonIcon,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonNote,
    IonText,
  },
  data() {
    return {
      save_id: null,
    };
  },
  computed: {
    ...mapState({
      userAvatar: (state) =>
        state.auth.currentUser && state.auth.currentUser.avatar
          ? state.auth.currentUser.avatar
          : false,
      userDisplayName: (state) =>
        state.auth.currentUser ? state.auth.currentUser.name : "",
    }),
    ...mapGetters({
      listSaves: "save/saves",
      currentSaveId: "save/currentSaveId",
      savesCount: "save/savesCount",
      currentSaveName: "save/currentSaveName",
      assetsCount: "account/currentSaveAssetsCount",
      categoriesCount: "account/currentSaveCategoriesCount",
    }),
    saveIonSelectInterface() {
      return this.savesCount <= 4 ? "popover" : null;
    },
    optionSaves() {
      const options = [];
      for (const save_id in this.listSaves) {
        options.push({ ...this.listSaves[save_id] });
      }
      if (options.length < 5) {
        options.push({
          save_id: -1, // create new save
          save_name: "(+) สร้างแฟ้มใหม่",
        });
      }
      return options;
    },
    icons() {
      return {
        accountOutline: gridOutline,
        accountSharp: gridSharp,
        categoryOutline: diceOutline,
        categorySharp: diceSharp,
        folderOutline: fileTrayStackedOutline,
        folderSharp: fileTrayStackedSharp,
        iconLogoutOutline: powerOutline,
        iconLogoutSharp: powerSharp,
        warningOutline,
        warningSharp,
        heartOutline,
        heartSharp,
        reportOutline: pieChartOutline,
        reportSharp: pieChartSharp,
      };
    },
  },
  methods: {
    ...mapActions({
      useSave: "save/useSave",
      createSave: "save/createSave",
      authLogout: "auth/logout",
      loadRecentTransactions: "save/loadRecentTransactions",
    }),
    async changeSaveId($event) {
      if ($event.detail && $event.detail.value) {
        const save_id = $event.detail.value;
        if (save_id > 0) {
          await this.useSave({ save_id });
          await this.loadRecentTransactions();
          this.save_id = save_id;
        } else if (save_id === -1) {
          await this.presentAlertPromptForCreateSave(
            () => {
              this.save_id = null;
            },
            async (data) => {
              const save_name = data.save_name;
              try {
                const save_id = await this.createSave({ save_name });
                await this.useSave({ save_id });
                this.save_id = save_id;
              } catch (e) {
                const extracted = extractAxiosErrorResponse(e);
                if (extracted) {
                  await this.alert(extracted);
                }
                this.save_id = null;
              }
            }
          );
        }
      }
    },
    async presentAlertPromptForCreateSave(onCancel, onOK) {
      const alert = await alertController.create({
        header: "สร้างแฟ้มใหม่",
        inputs: [
          {
            name: "save_name",
            placeholder: "ชื่อแฟ้ม",
          },
        ],
        buttons: [
          {
            text: "ยกเลิก",
            role: "cancel",
            handler: onCancel,
          },
          {
            text: "สร้าง",
            handler: (data) => onOK(data),
          },
        ],
      });
      return alert.present();
    },
    async actionLogout() {
      await this.authLogout();
      window.location.href = "/auth/lnwaccounts/redirect_for_logout";
    },
    async onClickLogoutButton() {
      const alert = await alertController.create({
        header: "ต้องการออกจากระบบ ?",
        buttons: [
          {
            text: "ยกเลิก",
            role: "cancel",
          },
          {
            text: "ยืนยัน",
            handler: async () => {
              await this.actionLogout();
            },
          },
        ],
      });
      return alert.present();
    },
  },
  mounted() {
    this.save_id = this.currentSaveId;
  },
};
</script>

<style scoped>
ion-item.profile {
  padding-top: 8px;
  padding-bottom: 8px;
}
ion-item.light {
  --background: var(--ion-color-light);
}
ion-list-header {
  --background: var(--ion-color-light);
  padding-bottom: 10px;
}
ion-item-divider {
  --background: var(--ion-color-light);
}
ion-content {
  --background: var(--ion-color-light);
}
ion-text.small {
  font-size: 80%;
}
</style>
